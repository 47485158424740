<template>
  <div class="flex">
    <div ref="featureTable" />
    <div class="ant-gis-feature-selector">
      <v-select
        :value="viewer.featureTableSelected"
        :items="viewer.featureTableConfigs"
        dense
        hide-details
        item-value="id"
        item-text="title"
        label=""
        :menu-props="{ offsetY: true }"
        full-width
        @input="changeTable"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'GisFeatureTable',
  props: {
    featureLayer: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['selectedSbsObject']),
    ...mapGetters('gisViewer', {
      viewer: 'viewer',
    }),
  },
  watch: {
    selectedSbsObject(newObject, oldObject) {
      if (newObject) {
        this.$store
          .dispatch('gisViewer/searchByCode', newObject.code)
          .then((results) => {
            if (!results.length) {
              this.$store.commit('showNotification', {
                content: `Search code not found for: ${newObject.label}`,
                color: 'error',
              });
              return;
            }

            if (results.length > 1) {
              console.error(
                `Found to many object with code: ${newObject.code}`
              );
            }

            this.$store.dispatch('gisViewer/highlightFeature', {
              item: results[0],
            });
          });
      } else {
        this.$store.dispatch('gisViewer/highlightFeatureClear');
        this.viewer.widgets.home.go();
      }
    },
  },
  mounted() {
    if (!this.viewer.featureTableSelected) {
      const id = this.viewer.featureTableConfigs?.[0]?.id;
      this.$store.dispatch('gisViewer/setViewerStoreProp', {
        key: 'featureTableSelected',
        value: id,
      });
    }
    this.loadFeatureTable();
  },
  destroyed() {
    if (this.viewer.featureTable) this.viewer.featureTable.destroy();
  },
  methods: {
    changeTable(val) {
      this.$store.dispatch('gisViewer/setViewerStoreProp', {
        key: 'featureTableSelected',
        value: val,
      });
      this.loadFeatureTable();
    },
    loadFeatureTable() {
      this.viewer.viewActive.when(() => {
        const featureLayer = this.viewer.featureLayers?.find(
          (l) => l.id === this.viewer.featureTableSelected
        );
        if (featureLayer) {
          this.loadFeatureLayer(featureLayer);
        } else {
          console.log('Error loadFeatureTable layer not found');
        }
      });
    },
    loadFeatureLayer(featureLayer) {
      if (this.viewer.featureTable) {
        this.viewer.featureTable.layer = featureLayer;
        return;
      }
      const featureTable = new this.viewer.classes.FeatureTable({
        container: this.$refs.featureTable,
        layer: featureLayer,
        view: this.viewer.viewActive, // required for feature highlight to work
        visibleElements: {
          // autocast to VisibleElements
          menuItems: {
            clearSelection: true,
            refreshData: true,
            toggleColumns: true,
            selectedRecordsShowAllToggle: true,
            selectedRecordsShowSelectedToggle: true,
            zoomToSelection: true,
          },
        },
      });
      featureTable.on('selection-change', (changes) => {
        console.log(changes);
        // If the selection is removed, remove the feature from the array
        changes.removed.forEach((item) => {
          const data = this.viewer.selectedFeatures.find((data) => {
            return data.feature === item.feature;
          });
          if (data) {
            this.viewer.selectedFeatures.splice(
              this.viewer.selectedFeatures.indexOf(data),
              1
            );
          }
        });
        // If the selection is added, push all added selections to array
        changes.added.forEach((item) => {
          const feature = item.feature;
          this.viewer.selectedFeatures.push({
            feature: feature,
          });
        });
      });
      this.$store.dispatch('gisViewer/setViewerProp', {
        key: 'featureTable',
        value: featureTable,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#featureTable {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  position: relative;
}
::v-deep {
  .esri-feature-table__header {
    height: 50px;
    align-items: center;
  }
  .esri-feature-table__title {
    display: flex;
    align-items: center;
  }
}
.ant-gis-feature-selector {
  position: absolute;
  top: 24px;
  right: 60px;
  z-index: 100;
  width: 150px;
}
</style>
